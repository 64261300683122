import { useNuxtApp } from "#app"
import { HIDE_API_LOADING, SHOW_API_LOADING } from "assets/events/api.js"
import { FLUSH_CART_STORE, SHOW_ERROR } from "assets/events/errors.js"
import * as Sentry from "@sentry/browser"
import { REFRESH_CART, USER_MUST_EDIT_SEAT_SELECTION } from "assets/events/cart.js"

export default defineNuxtPlugin((nuxtApp) => {
	const { $eventBus, $messages } = useNuxtApp()
	const runtimeConfig = useRuntimeConfig()
	const kioskModeEnabled = runtimeConfig.public.IS_KIOSK_APP

	const isCallbackFunc = (callback) => {
		return callback && typeof callback === "function"
	}

	const getErrorMsgFromResponse = (errorResponseBody) => {
		if (typeof errorResponseBody === "string") {
			return errorResponseBody
		} else if (typeof errorResponseBody === "object") {
			return errorResponseBody[Object.keys(errorResponseBody)[0]]
		}

		return $messages.$t("serverError")
	}

	const handleErrors = (error, errorMessage, requestOptions) => {
		$eventBus && $eventBus.$emit(HIDE_API_LOADING)

		const statusCode = error.statusCode
		// const responseStatus = error.data.status <-- responseStatus comes from the BE, but no real use for it yet
		const errorResponseBody = error.data

		if (statusCode >= 500) {
			logErrorInSentry(error, errorMessage, requestOptions)
			if (typeof errorResponseBody === "object" && !!errorResponseBody.errorMessage) {
				// Passed MS error from AWS
				$eventBus &&
					$eventBus.$emit(SHOW_ERROR, {
						titleText: $messages.$t("serverError"),
						bodyText: errorResponseBody.errorMessage,
					})
			} else if (error.message && error.message.indexOf("Failed to fetch") >= 0) {
				//The API call is not completing
				$eventBus &&
					$eventBus.$emit(SHOW_ERROR, {
						titleText: $messages.$t("poorConnectionTitle"),
						bodyText: $messages.$t("poorConnectionBody"),
					})
			} else {
				//Default Error Case
				$eventBus &&
					$eventBus.$emit(SHOW_ERROR, {
						titleText: $messages.$t("serverError"),
						bodyText: !!errorResponseBody ? errorResponseBody.message : errorMessage,
					})
			}
		} else if (statusCode >= 400) {
			if (kioskModeEnabled) {
				//wrapped this around kiosk mode flag, but this can be moved out for debugging purposes
				logErrorInSentry(error, errorMessage, requestOptions)
			}

			//This error occurs when somebody tries to checkout with a seat that is already booked
			//TODO need to refactor this to not be the same handler for all endpoints
			if (statusCode === 409) {
				$eventBus.$emit(REFRESH_CART, errorResponseBody.data.cart)
				const msg = errorResponseBody.data.invalidSeats
				$eventBus.$emit(SHOW_ERROR, {
					titleText: $messages.$t("errorPageTitle"),
					bodyText: msg,
					closeCallback: () => {
						$eventBus && $eventBus.$emit(USER_MUST_EDIT_SEAT_SELECTION)
					},
				})
				return
			}

			if (!errorResponseBody) {
				//400 error with no error message
				$eventBus.$emit(SHOW_ERROR, {
					titleText: $messages.$t("errorPageTitle"),
					bodyText: $messages.$t("errorPageBody"),
				})
				return
			}

			const msg = getErrorMsgFromResponse(errorResponseBody.data)

			if (msg.indexOf("Cart does not exist") >= 0) {
				//Somebody tried to modify an expired cart (if FE and BE are out of sync)
				$eventBus &&
					$eventBus.$emit(SHOW_ERROR, {
						titleText: $messages.$t("errorPopupDefaultTitle"),
						bodyText: msg,
						closeCallback: () => {
							$eventBus && $eventBus.$emit(FLUSH_CART_STORE)
						},
					})
			} else if (msg.indexOf("Out of stock") >= 0) {
				//If the quantity is out of stock but FE is out of date. Particularly on ticket launch.
				$eventBus &&
					$eventBus.$emit(SHOW_ERROR, {
						titleText: $messages.$t("unavailable"),
						bodyText: $messages.$t("soldOutAPIResponse"),
						closeCallback: () => {
							reloadNuxtApp()
						},
					})
			} else {
				$eventBus && $eventBus.$emit(SHOW_ERROR, { titleText: $messages.$t("tryAgain"), bodyText: msg })
			}
		} else if (statusCode >= 300) {
			//We should not be getting any 300 errors from the BE as of Aug 2023, so unhandled at this point
			//Default Error Message
			$eventBus &&
				$eventBus.$emit(SHOW_ERROR, {
					titleText: $messages.$t("serverError"),
					bodyText: $messages.$t("serverErrorUnhandled", { msg: errorMessage }),
				})
		}
	}

	const logErrorInSentry = (error, errorMessage, requestOptions) => {
		const errorString = error && typeof error === "object" ? JSON.stringify(error) : ""
		const requestOptionsString = error && typeof requestOptions === "object" ? JSON.stringify(requestOptions) : ""
		//Leave the console log here for Sentry to catch it
		console.log(`${errorMessage}: ${errorString}\nRequest: ${requestOptionsString}`)
		Sentry.captureMessage(`${errorMessage}: ${errorString}`, "error")
	}

	const showLoader = () => {
		$eventBus && $eventBus.$emit(SHOW_API_LOADING)
	}

	const hideLoader = () => {
		$eventBus && $eventBus.$emit(HIDE_API_LOADING)
	}

	const tryToHandleSuccessfulApiResponse = (status, callback, data) => {
		if (status.value === "success" && isCallbackFunc(callback)) {
			try {
				callback(data)
			} catch (e) {
				console.log("Received unexpected response format from server")
				console.log(e)
				console.log(data)
				Sentry.captureException(e)
			} finally {
				hideLoader()
			}
		}
	}

	nuxtApp.provide("apiHelper", {
		$showLoader: showLoader,

		$hideLoader: hideLoader,

		$initNoCachingHeader: () => {
			//Prevents Nuxt from caching API calls/responses
			return {
				key: new Date().getTime().toString(),
			}
		},

		//A fetch call during the setup method will be an asynchronous call where pending==true and a watcher on the data ref will be required
		//Calls that are made via user interactions will be returned immediately
		//Concerns: will the creation of watchers eventually degrade performance?
		//Internal API Standard: https://docs.google.com/document/d/1UexbHzfeE9xoDLsnjFNFc3HwpCFtl7IJYcfCuPkeDXc/edit?usp=sharing
		$handleAPIErrors: (pending, status, error, errorMessage = $messages.$t("serverError"), requestOptions) => {
			if (pending.value) {
				watch(error, (errorData) => {
					if (status.value === "error") {
						handleErrors(errorData, errorMessage, requestOptions)
					}
				})
			} else {
				if (status.value === "error") {
					handleErrors(error.value, errorMessage, requestOptions)
				}
			}
		},

		//A fetch call during the setup method will be an asynchronous call where pending==true and a watcher on the data ref will be required
		//Calls that are made via user interactions will be returned immediately
		//Concerns: will the creation of watchers eventually degrade performance?
		//Internal API Standard: https://docs.google.com/document/d/1UexbHzfeE9xoDLsnjFNFc3HwpCFtl7IJYcfCuPkeDXc/edit?usp=sharing
		$handleResponse: (pending, status, data, callback) => {
			if (pending.value) {
				watch(data, (watchedData) => {
					tryToHandleSuccessfulApiResponse(status, callback, watchedData ? watchedData.data : null)
				})
			} else {
				tryToHandleSuccessfulApiResponse(status, callback, data.value ? data.value.data : null)
			}
		},
	})
})
